<!--
 * @Author: CL
 * @Date: 2021-06-29 17:09:18
 * @LastEditTime: 2021-07-13 10:58:56
 * @Description: 联系方式的组件
-->

<template>
  <div class="concat-contain">
    <div>
      <IconFont type="wechat" :size="20" />
      <span>caolei18255650953</span>
    </div>
    <div>
      <IconFont type="qq" :size="20" />
      <span>2718865339</span>
    </div>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';

export default {
  components:{
    IconFont
  }
}
</script>

<style lang="less" scoped>
.concat-contain{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 25px;

  div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    >span{
      margin-left: 5px;
      font-size: 14px;
    }
  }
}
</style>
