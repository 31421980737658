<!--
 * @Author: CL
 * @Date: 2021-06-21 11:03:06
 * @LastEditTime: 2021-07-14 21:53:33
 * @Description: 首页
-->

<template>
  <div class="home">
    <div class="master-list">
      <Master 
        v-for="item in masterList" 
        :key="item.key" 
        :hairColor="item.hairColor" 
        :bodyColor="item.bodyColor" 
        :eyeColor="item.eyeColor" 
      />
    </div>
		<!-- 主体内容导航，标签 -->
    <div class="main-nav">
      <Nav />
      <Concat />
    </div>
    <!-- 网站备案号 -->
    <div class="site-save">
      <a target="_blank" href="http://beian.miit.gov.cn/">皖ICP备2021009894号</a>
    </div>
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import Concat from './components/Concat.vue';
import Master from '@/components/Master';

export default {
  name: 'Home',
	data(){
		return {
      masterList: [
        {
          id: 1,
          hairColor: '#F56C6C',
          eyeColor: '#0c0c63',
          bodyColor: '#ff9326'
        },
        {
          id: 2,
          hairColor: '#27ae60',
          eyeColor: '#2c3e50',
          bodyColor: '#2980b9'
        },
        {
          id: 3,
          hairColor: '#bbbb00',
          eyeColor: '#0080c0',
          bodyColor: '#8e44ad'
        }
      ]
		}
	},
	components: {
		Nav,
    Master,
    Concat
	}
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.home{
  min-width: 1200px;
  width: 100%;
  height: 100%;
  position: relative;
  background-image:linear-gradient(125deg,#2c3e50,#27ae60,#2980b9,#0080c0, #408080 ,#8e44ad);
	/* 让每一种颜色占据400% */
  background-size: 400%;   
  animation: move 15s infinite;

  .master-list{
    width: 24%;
    display: flex;
    justify-content: space-evenly;
    .self-center();
    top: 43%;
  }

  .main-nav{
    width: 100%;
    position: absolute;
    bottom: 30%;
    transform: translateY(-30%);
  }

  .site-save{
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);

    a{
      color: white;
      font-weight: bold;
    }
  }
}

@keyframes move {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
