<!--
 * @Author: CL
 * @Date: 2021-06-21 13:32:21
 * @LastEditTime: 2021-06-21 15:44:16
 * @Description: 怪物组件
 *
 * 1. hairColor: String   头部的颜色
 * 2. eyeColor:  String   眼睛的颜色
 * 3. bodyColor: String   身体的颜色
-->

<template>
  <div class="master-contain" :style="{ background: bodyColor, '--hcolor': hairColor }">
    <div class="eye">
      <div class="eyeball ball1" :style="{ background: eyeColor }"></div>
    </div>
    <div class="mouth"></div>
  </div>
</template>

<script>
export default {
  props: {
    hairColor: {
      type: String,
      default: '#F56C6C'
    },

    eyeColor: {
      type: String,
      default: '#0c0c63'
    },

    bodyColor: {
      type: String,
      default: '#ff9326'
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.master-contain{
  width: 60px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /*阴影设置*/
  position: relative;
  animation: jumping 0.8s infinite alternate;  /*infinite是循环的意思，alternate是反复的意思来回都有*/

  .eye{
    width:25px;
    height: 25px;
    background-color:#fff;
    border-radius: 50%;
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative;

    .eyeball{
      width:50%;
      height: 50%;
      border-radius: 50%;
      position: absolute;
      animation: eyesmove 1.6s infinite alternate;
    }
  }

  .mouth{
    width: 25px;
    height: 8px;
    background-color: #fff;
    border-radius: 15px;
    margin-top:10px;
  }

  &::before, &::after{
    content: "";
    width:15px;
    height: 8px;
    border-radius: 10px;
    background: var(--hcolor);
    position:absolute;
    left:50%;
    top:-10px;
  }

  &::before{
    transform: translateX(-86%) rotate(45deg);
  }
      
  &::after{
    transform: translateX(-21%) rotate(-45deg);  /*第一个是平移第二个是旋转*/
  }
}

@keyframes jumping{
  50%{
    transform: translateY(0);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  100%{
    transform: translateY(-25px);
    box-shadow: 0 100px 20px rgba(0, 0, 0, 0.26);
  }
}

@keyframes eyesmove{
  0%,10%{
    left:-1px;
  }
  90%,100%{
    left:12px;
  }
}
</style>
