<!--
 * @Author: CL
 * @Date: 2021-06-21 11:05:52
 * @LastEditTime: 2021-06-22 23:59:11
 * @Description: 首页的导航组件
 *
 * 这个组件的宽度有外层来决定
-->

<template>
  <div class="nav-contain" v-loading="loading">
    <router-link 
      v-for="item in navList" 
      :key="item.id"
      :to="{ name: item.path }"
      class="label-item"
    >
      <IconFont :type="item.type" :size="20" />
      <span>{{ item.name }}</span>
    </router-link>
    <router-link 
      :to="{ name: 'login' }"
      class="label-item"
      v-show="status == 'unlogin'"
    >
      <IconFont type="login" :size="20" />
      <span>登录</span>
    </router-link>
    <router-link 
      :to="{ name: 'manage' }"
      class="label-item"
      v-show="status == 'login'"
    >
      <IconFont type="manage" :size="20" />
      <span>后台管理</span>
    </router-link>
    <span 
      class="label-item"
      v-show="status == 'login'"
      @click="handleOut"
    >
      <IconFont type="logout" :size="20" />
      <span>退出登录</span>
    </span>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    IconFont
  },

  data(){
    return {
      
    }
  },

  computed: {
    ...mapState('nav', ['loading', 'navList']),
    ...mapGetters('loginUser', ['status'])
  },

  created(){
    this.$store.dispatch('nav/queryNav');
  },

  methods: {
    handleOut(){
      this.$store.dispatch('loginUser/logOut').then(res => {
        this.$showMessage({
          message: '退出成功',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.nav-contain{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .label-item{
    display: flex;
    text-align: center;
    align-items: center;
    margin: 0 10px;
    color: #fff;
    transition: all 1s;
    cursor: pointer;
  
    &:hover{
      color: #ff6060;
    }

    > span{
      display: inline-block;
      margin-left: 4px;
      font-size: 16px;
    }

    &:nth-of-type(1){
      > span{
        margin-left: 2px;
      }
    }
  }
}
</style>